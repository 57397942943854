import axios from 'axios';
import { Message } from 'element-ui'
import router from '@/router'
import {nanoid} from 'nanoid';
// import router from '@/router'
const host = process.env.NODE_ENV === "development" ? "/api" : "/soutu-server";
const service = axios.create({
    baseURL: host,
    //timeout: 10000 // 请求超时时间
});
// request拦截器
service.interceptors.request.use(config => {
    return config;
}, error => {
    // console.log(error); // for debug
    Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
    response => {
  if(response.data.code && response.data.code !== 200){
    Message({
        message: response.data.message || 'Error',
        type: 'error',
        duration: 2000
      })
  }
        return response;
    },
    error => {
        if(error.response.status === 401){
        localStorage.removeItem('UserInfo');
          Message({
              message: '请重新登录',
              type: 'error',
              duration: 2000
            });
            let nanoidStr = nanoid();
            let url = `https://qiandian8888.com/soutu-server/sys/thirdLogin/render/wechat_open?state=${nanoidStr}`;
            window.location.href = url;

        }
        console.log('拦截--', error.response.status+' '+error.response.statusText)
    }
)

export default service;
