import axios from './request';
// 登录接口
export function login(params){
  return axios({
      method:'get',
      url:'sys/thirdLogin/qrcode/wechat_mp',
      params
  })
}
//登录状态
export function loginState(params){
  return axios({
      method:'get',
      url:`sys/thirdLogin/state/${params}`,
      
  })
}
// 获取用户信息
export function getUserInfo(params){
  return axios({
      method:'get',
      url:'sys/user/getUserInfo',
      headers: {
        'X-Access-Token':params
      }
  })
}
//获取商品类列表
export function getGoodsList(params){
    return axios({
        method:'get',
        url:'weimob/goods/list',
        params
    })
}
// 获取类别列表
export function getClassify(){
	return axios({
	  method:'get',
	  url:'weimob/goods/classify',
	  params:''
  })
  }
//获取详情
  export function getDetails(params){
	return axios({
	  method:'get',
	  url:'weimob/goods/detail',
	  params
  })
  }
  // 获取链接
  export function getH5Url(params){
  return axios({
    method:'get',
    url:'weimob/goods/h5url',
    params
})
}
// 图片搜索
export function imgSearch(data){
  return axios({
    method:'post',
    url:'imagesearch/product/search',
    data:data
})
}



//获取地址列表
export function getAddressList(token){
  return axios({
    method:'post',
    url:'user/address/list',
    headers: {
      'X-Access-Token':token
    }
  })
}
//新增地址
export function addAddressList(data,token){
  return axios({
    method:'post',
    url:'user/address/add',
    data:data,
    headers: {
      'X-Access-Token':token
    }
  })
}
//修改地址
export function editAddressList(data,token){
  return axios({
    method:'post',
    url:'user/address/edit',
    data:data,
    headers: {
      'X-Access-Token':token
    }
  })
}
//删除地址
export function delAddressList(data,token){
  return axios({
    method:'delete',
    url:'user/address/del',
    data:data,
    headers: {
      'X-Access-Token':token
    }
  })
}
//设置默认地址
export function defaultAddressList(data,token){
  return axios({
    method:'post',
    url:'user/address/setDefault',
    data:data,
    headers: {
      'X-Access-Token':token
    }
  })
}

//提交订单
export function submitOrder(data,token){
  return axios({
    method:'post',
    url:'order/submitOrder',
    data:data,
    headers: {
      'X-Access-Token':token
    }
  })
}

//获取我的订单
export function getOrderList(data,token){
  return axios({
    method:'post',
    url:'order/list',
    data:data,
    headers: {
      'X-Access-Token':token
    }
  })
}

// 下载清单
export function exportOrder(data){
  return axios({
    method:'post',
    url:'order/exportXls',
    data:data,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json'
  },

})
}


//加入购物车
export function addCar(data,token){
  return axios({
    method:'post',
    url:'shoppingcart/save',
    data:data,
    headers: {
      'X-Access-Token':token
    }
  })
}

// 查询购物车
export function getCar(token){
  return axios({
    method:'post',
    url:'shoppingcart/get',
    headers: {
      'X-Access-Token':token
    }
  })
}

//查询token是否过期
export function checkTokenState(token){
  return axios({
    method:'post',
    url:'sys/thirdLogin/token/check',
    headers: {
      'X-Access-Token':token
    }
  })
}